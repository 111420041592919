import React from 'react';

import { Card } from '@components/layout/card';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { useDetectMedia } from '@hooks/useDetectMedia';

const PreCompleteContent = () => {
  const { md } = useDetectMedia();

  return (
    <Card
      height="max-content"
      width={md ? '750px' : '90%'}
      px={md ? '60px' : '20px'}
      py={md ? '60px' : '20px'}
      mx="auto"
      bg="gray-second"
    >
      <VStack spacing="15px" align="center">
        <Paragraph fontSize="30px" color="orange" align="center" fontWeight="bold">
          会員登録完了
        </Paragraph>
        <VStack py="6px" spacing="15px">
          <Paragraph fontSize="18px" color="black" align="center">
            ご登録ありがとうございました。
          </Paragraph>
          <Paragraph fontSize="18px" color="black" align="center" lh={md ? '20px' : '32px'}>
            ご登録のメールアドレス宛に「会員登録のお知らせ」を送信いたしました。
          </Paragraph>
          <Paragraph fontSize="18px" color="black" align="center" fontWeight="700">
            メールに記載のURLをご確認ください。
          </Paragraph>
        </VStack>
        <Paragraph fontSize="14px" color="black" align="center">
          *まだ登録は完了しておりません。
        </Paragraph>
      </VStack>
    </Card>
  );
};

export default React.memo(PreCompleteContent);
