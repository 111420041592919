import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import PreCompleteContent from '@features/signup/PreSignup/complete';

const PreComplete = () => (
  <Layout>
    <Helmet>
      <title>新規会員登録 | {title}</title>
      {NoIndex}
      {Description}
    </Helmet>
    <PreCompleteContent />
  </Layout>
);

export default React.memo(PreComplete);
